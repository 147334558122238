import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-data" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([`index-${$props.data}`])
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["index-icon fas", [$props.data ? 'fa-check' : 'fa-times']])
      }, null, 2 /* CLASS */),
      _createTextVNode("External Search Engines ")
    ], 2 /* CLASS */)
  ]))
}